.icon-section {
    display: block;
    padding: 80px 0;
    text-align: center;

    &.bottom-border {
        border-bottom: 1px solid #f3f3f3;
    }

    .column {
        span.icon {
            font-size: 4em;
            color: #eee;
        }

        h3, p {
            font-weight: 200;
            letter-spacing: 1px;
            margin: 20px 0 0 0;
            color: #777;
        }

        p {
            font-size: 1em;
            color: #999;
            line-height: 1.8em;
            margin: 10px 0 0 0;
        }

        h3 {
            font-family: 'Lato';
        }

        &:hover {
            cursor: pointer;

            h3, p {
                color: #111;
            }

            .icon {
                color: #aaa;
            }
        }
    }
}
