body {
    font-family: 'Lato', 'Myriad Set Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.form-group.no-pad {
    margin-bottom: 0;
}

.text-left {
    p {
        text-align: left !important;
    }
}

.text-right {
    p {
        text-align: right !important;
    }
}

.text-justified {
    p {
        text-align: justify !important;
    }
}

#contact {
    background-color: #15454d;
    color: #fff;
}

.flash-message {
    @include background-opacity($brand-primary, 0.75);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999999;
    padding: 20px;
    width: 100%;
    width: 100vw;
    font-size: 1.2em;
    visibility: visible;

    &.slidein {
        // max-height: 0;
        // overflow-y: hidden;
        // transition: max-height 0.5s ease-in-out;
        // transition: top 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
        // visibility: hidden;

        // animation-name: pullDown;
        // animation-duration: 10s;
        // animation-timing-function: ease-in-out;

        // transform-origin: 50% 0%;
    }

    &.success {
        @include background-opacity($brand-success, 0.75);
    }

    &.info {
        @include background-opacity($brand-info, 0.75);
    }

    &.warning {
        @include background-opacity($brand-warning, 0.75);
    }

    &.danger {
        @include background-opacity($brand-danger, 0.75);
    }
}

.slideDown {
    animation-name: slideDown;
    animation-duration: 10s;
    animation-timing-function: ease-in-out;
    visibility: hidden;
    opacity: 0;
    // transition: visibility 0s 10s, opacity 9s linear;
}

@keyframes slideDown {
    0% {
        transform: translateY(-100%);
        visibility: visible;
        opacity: 1;
    }

    5% {
        transform: translateY(0%);
    }

    12% {
        transform: translateY(-4%);
    }

    20% {
        transform: translateY(0%);
    }

    12% {
        transform: translateY(-2%);
    }

    20% {
        transform: translateY(0%);
    }

    // ---------------

    80% {
        transform: translateY(0%);
    }

    88% {
        transform: translateY(-2%);
    }

    80% {
        transform: translateY(0%);
    }

    88% {
        transform: translateY(-4%);
    }

    95% {
        transform: translateY(0%);
    }

    100% {
        transform: translateY(-100%);
        visibility: hidden;
        opacity: 0;
    }
}

.addresses {
    a {
        color: #fff;

        &:hover {
            text-decoration: underline;
        }
    }
}

@media (min-width: 992px) {
    .flex-wrap-default {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex: 0 0 auto;
        flex-direction: row;
        align-content: stretch;
        flex-wrap: wrap;
    }

    .equal > div[class*='col-'] {
        @extend .flex-wrap-default;
    }

    .equal {
        @extend .flex-wrap-default;

        a.btn {
            align-self: flex-end;
        }

        .panel {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            flex-direction: column;
            align-content: stretch;

            & > .panel-body {
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                flex-grow: 1;
                flex-direction: column;
            }
        }
    }
}
