.carousel {
    .item {
        img {
            width: 100%;
        }
    }

    .carousel-caption {
        bottom: auto;
        top: 30px;
        // max-width: 500px;
        margin: 0 auto;

        h2, p {
            color: #fff;
            font-weight: 300;
            text-shadow: 0;
            // font-size: 2em;
            // line-height: 1.5em;
            letter-spacing: 1px;
            // margin-top: -30px;
        }

        h2 {
            font-family: 'Lato';
        }

        p {
            font-size: 1.1em;
            max-width: 80%;
            margin: 20px auto 0 auto;
        }

        .buttons {
            margin: 40px 0 0 0;

            a.btn {
                border: none;
                padding: 10px 18px;
                margin: 0 10px;

                span {
                    margin-right: 10px;
                }

                &.btn-primary {
                    background: $primary_colour;

                    &:hover {
                        background: #fff;
                        color: $primary_colour;
                    }
                }

                &.btn-default {
                    color: #666;

                    &:hover {
                        background: $primary_colour;
                        color: #fff;
                    }
                }
            }
        }
    }
}
