nav.navbar {
    background: #fff;
    border: none;
    padding: 0;
    margin: 0;

    .navbar-toggle {
        padding: 12px 10px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .navbar-brand {
        height: auto;

        img {
            width: 150px;
        }
    }

    .navbar-nav {
        text-align: center;

        @media (min-width: 768px) {
            float: right;
            text-align: left;
        }

        li {
            a {
                line-height: 25px;
                padding: 0;
                font-weight: 600;
                text-transform: uppercase;
                font-size: 0.8em;
                margin-left: 30px;

                @media (min-width: 768px) {
                    height: 82px;
                    line-height: 82px;

                    &:hover {
                        background: none;
                    }
                }
            }

            &.active {
                a {
                    color: $primary_colour;
                    background: none;

                    &:hover {
                        background: none;
                        color: $primary_colour;
                    }
                }
            }
        }
    }
}
