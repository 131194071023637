.page-title {
    background: #333;
    display: block;
    padding: 40px 0;

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6,
    p {
        color: #fff;
        font-weight: 300;
        text-shadow: 0;
        // font-size: 2em;
        line-height: 1.5em;
        letter-spacing: 1px;
        text-align: center;
        margin: 0;
        padding: 0;
    }

    p {
        font-size: 1.1em;
        max-width: 80%;
        margin: 15px auto 0 auto;
        color: #ccc;
    }

    img.img-thumbnail {
        background-color: lighten(#333, 25%);
    }

    .categories {
        color: #fefefe;

        a, a:visited {
            color: inherit;
        }
    }
}
