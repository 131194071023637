.clients-section {
    display: block;
    padding: 40px 0;
    background: #f9f9f9;

    .title {
        display: block;
        margin: 0;
        padding: 0;
        font-size: 1.7em;
        color: #aaa;
        // text-transform: uppercase;
        text-align: center;
        font-weight: 200;
    }

    ul {
        display: block;
        margin: 40px 0 0 0;
        text-align: center;

        li {
            display: inline-block;
            margin: 0 30px;
            opacity: 0.6;

            &:hover {
                opacity: 1;
            }
        }
    }
}
