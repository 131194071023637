footer {
    display: block;
    background: #15454d;
    padding: 20px 0;

    p {
        display: block;
        padding: 0;
        margin: 0;
        color: #fff;
        font-weight: 200;

        a {
            color: #fff;

            &:hover {
                color: #fff;
                text-decoration: underline;
            }
        }
    }
}
