.text-section {
    display: block;
    // padding: 80px 0;

    &.bottom-border {
        border-bottom: 1px solid #f3f3f3;
    }

    h3, h4, p {
        display: block;
        margin: 20px 0 0 0;
        // color: #777;
        // font-weight: 200;
        // font-size: 2em;
        letter-spacing: 1px;
        line-height: 1.3em;
    }

    &.homepage {
        h3, h4, p {
            margin: 22px 0px -20px 0;
        }
    }

    p {
        // font-size: 1.2em;
        margin: 20px 0 0 0;
        line-height: 1.7em;
        text-align: justify;
        color: #999;
        letter-spacing: 0;
    }

    h3 {
        font-family: 'Lato';
    }

    .safety-images {
        img {
            height: 40px !important;
            margin: 0 auto;
        }
    }

    &.inverted {
        img {
            padding-left: 0;
            padding-right: 60px;
        }
    }

    .panel {
        margin-top: 30px;

        .panel-title {
            margin: auto;
            font-size: 1.5em;
        }
    }
}

.panel {
    p {
        margin: 0;
    }
}
